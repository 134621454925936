import UAE from "UAE";
import AbstractThemeMenu from "./AbstractThemeMenu";

class ThemeMenu9 extends AbstractThemeMenu {
    constructor() {
        super(UAE.goFactory);
    }

    init() {
        super.init('k1_t9_menu', 'k1_t9_menu');
    }

    protected _createButtons() {
        this._createButton('t9_lesson_menu_l1', () => { });
        this._createButton('t9_lesson_menu_l2', () => { });
        this._createButton('t9_lesson_menu_l3', () => { });
        this._createButton('t9_lesson_menu_l4', () => { });
        this._createButton('t9_lesson_menu_l5', () => { });
        this._createButton('t9_lesson_menu_l6', () => { });
        // this._createButton('t3_lesson_menu_l5', () => { UAE.debug.info('clicked button k3_theme_4_menu') }, () => { });
        super._createButtons();
    }


    protected _createLessonLinks(){
        this._genLinksForLesson('t9_lesson_menu_l1', 9, 1);
        this._genLinksForLesson('t9_lesson_menu_l2', 9, 2);
        this._genLinksForLesson('t9_lesson_menu_l3', 9, 3);
        this._genLinksForLesson('t9_lesson_menu_l4', 9, 4);
        this._genLinksForLesson('t9_lesson_menu_l5', 9, 5);
        this._genLinksForLesson('t9_lesson_menu_l6', 9, 6);
        super._createLessonLinks();
    }

    protected _onBackPressed() {
        this._goto('main_menu_3');
    }
}

export default ThemeMenu9;