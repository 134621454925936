import TypeFactory from "Game/GO/TypeFactory";
import UAE from "UAE";
import Button from "UAE/Core/Engine/GameObjects/Button";
import SpriteObject from "UAE/Core/Engine/GameObjects/SpriteObject";

class BaseMainMenu extends UAE.activities.BaseMenu {
  protected _buttons: Button[];
  protected _menuTab: SpriteObject;
  protected _factory: TypeFactory;

  constructor(factory: TypeFactory) {
    super(UAE.activities.components.events(), UAE.loop, UAE.goFactory, UAE.loader, UAE.levelManager, UAE.game);
    this._factory = factory;

  }

  preload(){
    this._loader.addAtlases(['k1_back_button', 'k1_forward_button']);
    super.preload();
  }

  init(sceneName: string, bgdName: string): void {
    super.init(sceneName, bgdName, true);
    this._buttons = [];
  }

  protected _createButton(sprite: string, name: string, onDown: Function, onOver: Function) {
    let button = this._goFactory.button(0, 0, sprite, 'up', { up: 'up', down: 'over', over: 'over', out: 'up' }, onDown, this, () => { }, onOver, this._playground, true);
    button.sprite.name = name;
    this._buttons.push(button);
  }

  protected _createMenuTab() {
    this._menuTab = this._goFactory.sprite(0, 0, 'k1_menu_tab', '', this._foreground, true);
    this._menuTab.animations.importAnimations();
  }

  protected _showLeft(onLeftPressed: Function) {
    let left = this._goFactory.button(10, UAE.game.height() - 100, 'k1_back_button', 'up', {up: 'up', down: 'over', over: 'over', out: 'up'}, onLeftPressed);
    left.setOrigin(0, 0.5);
  }

  protected _showRight(onRightPressed: Function) {
    let right = this._goFactory.button(UAE.game.width() - 10, UAE.game.height() - 100, 'k1_forward_button', 'up', {up: 'up', down: 'over', over: 'over', out: 'up'}, onRightPressed);
    right.setOrigin(1, 0.5);
  }
}

export default BaseMainMenu;