import UAE from "UAE";
import AbstractThemeMenu from "./AbstractThemeMenu";

class ThemeMenu2 extends AbstractThemeMenu {
    constructor() {
        super(UAE.goFactory);
    }

    init() {
        super.init('k1_t2_menu', 'k1_t2_menu');
    }

    protected _createButtons() {
        this._createButton('t2_lesson_menu_l1', () => { });
        this._createButton('t2_lesson_menu_l2', () => { });
        this._createButton('t2_lesson_menu_l3', () => { });
        this._createButton('t2_lesson_menu_l4', () => { });
        this._createButton('t2_lesson_menu_l5', () => { });
        this._createButton('t2_lesson_menu_l6', () => { });
        super._createButtons();
    }

    protected _createLessonLinks(){
        this._genLinksForLesson('t2_lesson_menu_l1', 2, 1);
        this._genLinksForLesson('t2_lesson_menu_l2', 2, 2);
        this._genLinksForLesson('t2_lesson_menu_l3', 2, 3);
        this._genLinksForLesson('t2_lesson_menu_l4', 2, 4);
        this._genLinksForLesson('t2_lesson_menu_l5', 2, 5);
        this._genLinksForLesson('t2_lesson_menu_l6', 2, 6);
        super._createLessonLinks();
    }


}

export default ThemeMenu2;