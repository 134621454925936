
import TypeFactory from '../GO/TypeFactory';
import UAE from 'UAE';
import BaseMainMenu from './BaseMainMenu';

class MainMenu3 extends BaseMainMenu {
  // Boilerplate members first

  constructor(factory: TypeFactory) {
    super(factory);
  }

  init(): void {
    UAE.debug.info('main_menu_3');
    super.init('main_menu_3', 'k1_main_menu_3_bg');
  }

  preload(){
    this._loader.addSnds(['mus_k1_menu_t9_t12']);
    super.preload();
  }

  start() {
    console.log('start executed in MainMenu3 of k1');
    UAE.events.emit('main_menu_3_start');
    this._createMenuTab();
    this._createButtons();
    super.start();
    UAE.events.emit('hide_bar');
    this._showLeft(this._onPrevTapped.bind(this));
    //   UAE.events.emit('show_left');
    // UAE.events.on('prev_tapped', this._onPrevTapped, this);
    this._events.timer(() => {
    this._manager.audio.playMusic('mus_k1_menu_t9_t12', () => { }, true);
  }, 500, this);
  }

  protected _onPrevTapped() {
    this._goto('main_menu_2');
  }

  protected _createButtons() {
    this._createButton('k1_theme_9_menu', 'k1_theme_9_menu', () => { this._goto('k1_t9_menu') }, () => { this._menuTab.animations.play('k1-menu-1-t9') });
    this._createButton('k1_theme_10_menu', 'k1_theme_10_menu', () => { this._goto('k1_t10_menu') }, () => { this._menuTab.animations.play('k1-menu-1-t10') });
    this._createButton('k1_theme_11_menu', 'k1_theme_11_menu', () => { this._goto('k1_t11_menu') }, () => { this._menuTab.animations.play('k1-menu-1-t11') });
    this._createButton('k1_theme_12_menu', 'k1_theme_12_menu', () => { this._goto('k1_t12_menu') }, () => { this._menuTab.animations.play('k1-menu-1-t12') });
    UAE.debug.exposeGlobal(this._buttons, 'buttons');
  }

  shutdown() {
    //   UAE.events.off('prev_tapped', this._onPrevTapped, this);
    super.shutdown();
  }

}

export default MainMenu3;