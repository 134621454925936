
import TypeFactory from '../GO/TypeFactory';
import UAE from 'UAE';
import BaseMainMenu from './BaseMainMenu';

class MainMenu extends BaseMainMenu {
  // Boilerplate members first


  constructor(factory: TypeFactory) {
    super(factory);
  }

  init(): void {
    UAE.debug.info('main_menu');
    super.init('main_menu', 'k1_main_menu_1_bg');
  }

  preload() {
    this._loader.addSnds(['mus_k1_menu_t1_t4']);
    super.preload();
  }

  start() {
    console.log('start executed in MainMenu of k1');
    UAE.events.emit('main_menu_1_start');
    this._createMenuTab();
    this._createButtons();
    super.start();
    UAE.events.emit('hide_bar');
    this._showRight(this._onForwardTapped.bind(this));
    //  UAE.events.emit('show_right');
    //  UAE.events.on('forward_tapped', this._onForwardTapped, this);
    this._events.timer(() => {
      this._manager.audio.playMusic('mus_k1_menu_t1_t4', () => { }, true);
    }, 500, this);
  }

  protected _onForwardTapped() {
    this._goto('main_menu_2');
  }

  protected _createButtons() {
    this._createButton('k1_theme_1_menu', 'k1_theme_1_menu', () => { this._goto('k1_t1_menu') }, () => { this._menuTab.animations.play('k1-menu-1-t1') });
    this._createButton('k1_theme_2_menu', 'k1_theme_2_menu', () => { this._goto('k1_t2_menu') }, () => { this._menuTab.animations.play('k1-menu-1-t2') });
    this._createButton('k1_theme_3_menu', 'k1_theme_3_menu', () => { this._goto('k1_t3_menu') }, () => { this._menuTab.animations.play('k1-menu-1-t3') });
    this._createButton('k1_theme_4_menu', 'k1_theme_4_menu', () => { this._goto('k1_t4_menu') }, () => { this._menuTab.animations.play('k1-menu-1-t4') });
    UAE.debug.exposeGlobal(this._buttons, 'buttons');
  }

  shutdown() {
    // UAE.events.off('forward_tapped', this._onForwardTapped, this);
    super.shutdown();
  }

}

export default MainMenu;