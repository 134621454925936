import UAE from "UAE";

class TypeFactory {

    constructor(){

        UAE.debug.exposeGlobal(this, 'fact');
    }

}

export default TypeFactory;