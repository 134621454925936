import UAE from "UAE";
import AbstractThemeMenu from "./AbstractThemeMenu";

class ThemeMenu10 extends AbstractThemeMenu {
    constructor() {
        super(UAE.goFactory);
    }

    init() {
        super.init('k1_t10_menu', 'k1_t10_menu');
    }

    protected _createButtons() {
        this._createButton('t10_lesson_menu_l1', () => { });
        this._createButton('t10_lesson_menu_l2', () => { });
        this._createButton('t10_lesson_menu_l3', () => { });
        this._createButton('t10_lesson_menu_l4', () => { });
        this._createButton('t10_lesson_menu_l5', () => { });
        this._createButton('t10_lesson_menu_l6', () => { });
        // this._createButton('t3_lesson_menu_l5', () => { UAE.debug.info('clicked button k3_theme_4_menu') }, () => { });
        super._createButtons();
    }


    protected _createLessonLinks(){
        this._genLinksForLesson('t10_lesson_menu_l1', 10, 1);
        this._genLinksForLesson('t10_lesson_menu_l2', 10, 2);
        this._genLinksForLesson('t10_lesson_menu_l3', 10, 3);
        this._genLinksForLesson('t10_lesson_menu_l4', 10, 4);
        this._genLinksForLesson('t10_lesson_menu_l5', 10, 5);
        this._genLinksForLesson('t10_lesson_menu_l6', 10, 6);
        super._createLessonLinks();
    }

    protected _onBackPressed() {
        this._goto('main_menu_3');
    }
}

export default ThemeMenu10;