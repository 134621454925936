
import TypeFactory from '../GO/TypeFactory';
import UAE from 'UAE';
import BaseMainMenu from './BaseMainMenu';

class MainMenu2 extends BaseMainMenu {
  // Boilerplate members first
  constructor(factory: TypeFactory) {
    super(factory);
  }

  init(): void {
    UAE.debug.info('main_menu_2');
    super.init('main_menu_2', 'k1_main_menu_2_bg');
  }

  preload() {
    this._loader.addSnds(['mus_k1_menu_t5_t8']);
    super.preload();
  }

  start() {
    console.log('start executed in MainMenu2 of k1');
    UAE.events.emit('main_menu_2_start');
    this._createMenuTab();
    this._createButtons();
    super.start();
    UAE.events.emit('hide_bar');
    //  UAE.events.emit('show_right');
    //  UAE.events.emit('show_left');
    this._showLeft(this._onPrevTapped.bind(this));
    this._showRight(this._onForwardTapped.bind(this));
    //  UAE.events.on('prev_tapped', this._onPrevTapped, this);
    //  UAE.events.on('forward_tapped', this._onForwardTapped, this);
    this._events.timer(() => {
      this._manager.audio.playMusic('mus_k1_menu_t5_t8', () => { }, true);
    }, 500, this);

  }

  protected _onPrevTapped() {
    this._goto('main_menu');
  }

  protected _onForwardTapped() {
    this._goto('main_menu_3');
  }

  protected _createButtons() {
    this._createButton('k1_theme_5_menu', 'k1_theme_5_menu', () => { this._goto('k1_t5_menu') }, () => { this._menuTab.animations.play('k1-menu-1-t5') });
    this._createButton('k1_theme_6_menu', 'k1_theme_6_menu', () => { this._goto('k1_t6_menu') }, () => { this._menuTab.animations.play('k1-menu-1-t6') });
    this._createButton('k1_theme_7_menu', 'k1_theme_7_menu', () => { this._goto('k1_t7_menu') }, () => { this._menuTab.animations.play('k1-menu-1-t7') });
    this._createButton('k1_theme_8_menu', 'k1_theme_8_menu', () => { this._goto('k1_t8_menu') }, () => { this._menuTab.animations.play('k1-menu-1-t8') });
    UAE.debug.exposeGlobal(this._buttons, 'buttons');
  }

  shutdown() {
    //  UAE.events.off('prev_tapped', this._onPrevTapped, this);
    //  UAE.events.off('forward_tapped', this._onForwardTapped, this);
    super.shutdown();
  }

}

export default MainMenu2;