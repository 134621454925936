import UAE from "UAE";
import AbstractThemeMenu from "./AbstractThemeMenu";

class ThemeMenu1 extends AbstractThemeMenu {
    constructor() {
        super(UAE.goFactory);
    }

    init() {
        super.init('k1_t1_menu', 'k1_t1_menu');
    }

    protected _createButtons() {
        this._createButton('t1_lesson_menu_l1', () => { });
        this._createButton('t1_lesson_menu_l2', () => { });
        this._createButton('t1_lesson_menu_l3', () => { });
        this._createButton('t1_lesson_menu_l4', () => { });
        this._createButton('t1_lesson_menu_l5', () => { });
        this._createButton('t1_lesson_menu_l6', () => { });
        this._createButton('t1_lesson_menu_l7', () => { });
        super._createButtons();
    }

    protected _createLessonLinks() {
        this._genLinksForLesson('t1_lesson_menu_l1', 1, 1);
        this._genLinksForLesson('t1_lesson_menu_l2', 1, 2);
        this._genLinksForLesson('t1_lesson_menu_l3', 1, 3);
        this._genLinksForLesson('t1_lesson_menu_l4', 1, 4);
        this._genLinksForLesson('t1_lesson_menu_l5', 1, 5);
        this._genLinksForLesson('t1_lesson_menu_l6', 1, 6);
        this._genLinksForLesson('t1_lesson_menu_l7', 1, 7);
        super._createLessonLinks();
    }
}

export default ThemeMenu1;