import SmartDepend from '../Dep/SmartDepend';

import Activity from '../../src/Game/Control/Activity';
import ActivityBar from '../../src/Game/GO/ActivityBar';
import TypeFactory from '../../src/Game/GO/TypeFactory';
import AbstractThemeMenu from '../../src/Game/Levels/AbstractThemeMenu';
import BaseMainMenu from '../../src/Game/Levels/BaseMainMenu';
import MainMenu from '../../src/Game/Levels/MainMenu';
import MainMenu2 from '../../src/Game/Levels/MainMenu2';
import MainMenu3 from '../../src/Game/Levels/MainMenu3';
import ThemeMenu1 from '../../src/Game/Levels/ThemeMenu1';
import ThemeMenu10 from '../../src/Game/Levels/ThemeMenu10';
import ThemeMenu11 from '../../src/Game/Levels/ThemeMenu11';
import ThemeMenu12 from '../../src/Game/Levels/ThemeMenu12';
import ThemeMenu2 from '../../src/Game/Levels/ThemeMenu2';
import ThemeMenu3 from '../../src/Game/Levels/ThemeMenu3';
import ThemeMenu4 from '../../src/Game/Levels/ThemeMenu4';
import ThemeMenu5 from '../../src/Game/Levels/ThemeMenu5';
import ThemeMenu6 from '../../src/Game/Levels/ThemeMenu6';
import ThemeMenu7 from '../../src/Game/Levels/ThemeMenu7';
import ThemeMenu8 from '../../src/Game/Levels/ThemeMenu8';
import ThemeMenu9 from '../../src/Game/Levels/ThemeMenu9';




class ControlContainer {
  private _smartDepend: SmartDepend;

  protected _Activity: any;
protected _ActivityBar: any;
protected _TypeFactory: any;
protected _AbstractThemeMenu: any;
protected _BaseMainMenu: any;
protected _MainMenu: any;
protected _MainMenu2: any;
protected _MainMenu3: any;
protected _ThemeMenu1: any;
protected _ThemeMenu10: any;
protected _ThemeMenu11: any;
protected _ThemeMenu12: any;
protected _ThemeMenu2: any;
protected _ThemeMenu3: any;
protected _ThemeMenu4: any;
protected _ThemeMenu5: any;
protected _ThemeMenu6: any;
protected _ThemeMenu7: any;
protected _ThemeMenu8: any;
protected _ThemeMenu9: any;


  constructor() {
    this._smartDepend = new SmartDepend();

    this._addModules();
    this._addDepends();
  }

  public getMain(): Activity {
    let spEntity = <Activity>this._smartDepend.resolve(this._Activity);

    return spEntity;
  }

  private _addModules() {
    this._Activity = this._smartDepend.addModule(Activity, false);
this._ActivityBar = this._smartDepend.addModule(ActivityBar, false);
this._TypeFactory = this._smartDepend.addModule(TypeFactory, false);
this._AbstractThemeMenu = this._smartDepend.addModule(AbstractThemeMenu, false);
this._BaseMainMenu = this._smartDepend.addModule(BaseMainMenu, false);
this._MainMenu = this._smartDepend.addModule(MainMenu, false);
this._MainMenu2 = this._smartDepend.addModule(MainMenu2, false);
this._MainMenu3 = this._smartDepend.addModule(MainMenu3, false);
this._ThemeMenu1 = this._smartDepend.addModule(ThemeMenu1, false);
this._ThemeMenu10 = this._smartDepend.addModule(ThemeMenu10, false);
this._ThemeMenu11 = this._smartDepend.addModule(ThemeMenu11, false);
this._ThemeMenu12 = this._smartDepend.addModule(ThemeMenu12, false);
this._ThemeMenu2 = this._smartDepend.addModule(ThemeMenu2, false);
this._ThemeMenu3 = this._smartDepend.addModule(ThemeMenu3, false);
this._ThemeMenu4 = this._smartDepend.addModule(ThemeMenu4, false);
this._ThemeMenu5 = this._smartDepend.addModule(ThemeMenu5, false);
this._ThemeMenu6 = this._smartDepend.addModule(ThemeMenu6, false);
this._ThemeMenu7 = this._smartDepend.addModule(ThemeMenu7, false);
this._ThemeMenu8 = this._smartDepend.addModule(ThemeMenu8, false);
this._ThemeMenu9 = this._smartDepend.addModule(ThemeMenu9, false);

  }

  private _addDepends() {
    this._smartDepend.addDependency(this._Activity, this._TypeFactory);
this._smartDepend.addDependency(this._Activity, this._MainMenu);
this._smartDepend.addDependency(this._Activity, this._MainMenu2);
this._smartDepend.addDependency(this._Activity, this._MainMenu3);
this._smartDepend.addDependency(this._Activity, this._ThemeMenu1);
this._smartDepend.addDependency(this._Activity, this._ThemeMenu2);
this._smartDepend.addDependency(this._Activity, this._ThemeMenu3);
this._smartDepend.addDependency(this._Activity, this._ThemeMenu4);
this._smartDepend.addDependency(this._Activity, this._ThemeMenu5);
this._smartDepend.addDependency(this._Activity, this._ThemeMenu6);
this._smartDepend.addDependency(this._Activity, this._ThemeMenu7);
this._smartDepend.addDependency(this._Activity, this._ThemeMenu8);
this._smartDepend.addDependency(this._Activity, this._ThemeMenu9);
this._smartDepend.addDependency(this._Activity, this._ThemeMenu10);
this._smartDepend.addDependency(this._Activity, this._ThemeMenu11);
this._smartDepend.addDependency(this._Activity, this._ThemeMenu12);


this._smartDepend.addDependency(this._AbstractThemeMenu, this._TypeFactory);


this._smartDepend.addDependency(this._BaseMainMenu, this._TypeFactory);


this._smartDepend.addDependency(this._MainMenu, this._TypeFactory);


this._smartDepend.addDependency(this._MainMenu2, this._TypeFactory);


this._smartDepend.addDependency(this._MainMenu3, this._TypeFactory);



  }

}

export default ControlContainer;