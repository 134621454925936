import UAE from "UAE";
import AbstractThemeMenu from "./AbstractThemeMenu";

class ThemeMenu3 extends AbstractThemeMenu {
    constructor() {
        super(UAE.goFactory);
    }

    init() {
        super.init('k1_t3_menu', 'k1_t3_menu');
    }

    protected _createButtons() {
        this._createButton('t3_lesson_menu_l1', () => { });
        this._createButton('t3_lesson_menu_l2', () => { });
        this._createButton('t3_lesson_menu_l3', () => { });
        this._createButton('t3_lesson_menu_l4', () => { });
        this._createButton('t3_lesson_menu_l5', () => { });
        super._createButtons();
    }

    protected _createLessonLinks(){
        this._genLinksForLesson('t3_lesson_menu_l1', 3, 1);
        this._genLinksForLesson('t3_lesson_menu_l2', 3, 2);
        this._genLinksForLesson('t3_lesson_menu_l3', 3, 3);
        this._genLinksForLesson('t3_lesson_menu_l4', 3, 4);
        this._genLinksForLesson('t3_lesson_menu_l5', 3, 5);
        super._createLessonLinks();
    }


}

export default ThemeMenu3;