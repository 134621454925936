import UAE from "UAE";
import AbstractThemeMenu from "./AbstractThemeMenu";

class ThemeMenu7 extends AbstractThemeMenu {
    constructor() {
        super(UAE.goFactory);
    }

    init() {
        super.init('k1_t7_menu', 'k1_t7_submenu');
    }

    protected _createButtons() {
        this._createButton('t7_lesson_menu_l1', () => { });
        this._createButton('t7_lesson_menu_l2', () => { });
        this._createButton('t7_lesson_menu_l3', () => { });
        this._createButton('t7_lesson_menu_l4', () => { });
        this._createButton('t7_lesson_menu_l5', () => { });
        super._createButtons();
    }


    protected _createLessonLinks(){
        this._genLinksForLesson('t7_lesson_menu_l1', 7, 1);
        this._genLinksForLesson('t7_lesson_menu_l2', 7, 2);
        this._genLinksForLesson('t7_lesson_menu_l3', 7, 3);
        this._genLinksForLesson('t7_lesson_menu_l4', 7, 4);
        this._genLinksForLesson('t7_lesson_menu_l5', 7, 5);
        super._createLessonLinks();
    }

    protected _onBackPressed() {
        this._goto('main_menu_2');
    }
}

export default ThemeMenu7;