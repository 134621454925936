
import TypeFactory from '../GO/TypeFactory';
import UAE from 'UAE';
import SpriteObject from 'UAE/Core/Engine/GameObjects/SpriteObject';
import MenuBar from 'UAE/Core/Engine/GameObjects/MenuBar';
import Button from 'UAE/Core/Engine/GameObjects/Button';

interface LessonLinks {
    lesson: string;
    practice: string;
    wrapup: string;
    explore: string;
}

class AbstractThemeMenu extends UAE.activities.BaseMenu {
    // Boilerplate members first
    protected _factory: TypeFactory;
    protected _buttons: SpriteObject[];
    protected _menu: MenuBar;
    protected _linksMap: Map<string, LessonLinks>;
    protected _currentLinks: LessonLinks;
    protected _getScript: Function;
    protected _workoutButton: Button;

    constructor(factory: TypeFactory) {
        super(UAE.activities.components.events(), UAE.loop, UAE.goFactory, UAE.loader, UAE.levelManager, UAE.game)
        this._factory = factory;
    }

    init(sceneName: string, bgName: string): void {
        super.init(sceneName, bgName, true);
        this._linksMap = new Map<string, LessonLinks>();
        this._manager.globalEvents.emit('theme_menu_init');
        this._getScript = UAE.game.product.getScript.bind(UAE.game.product);

        this._manager.globalEvents.on('prev_tapped', this._onBackPressed, this);
        this._buttons = [];
    }
    

    protected _onBackPressed() {
        this._goto('main_menu');
    }

    preload() {
        this._loader.addImages(['k1_submenu_nav'], 'png');
        super.preload();
    }

    start() {
        UAE.events.emit('hide_bar');
        this._manager.globalEvents.emit('theme_menu_start');
        this._createButtons();
        this._createLessonLinks();
        this._createMenu();
        super.start();
    }

    protected _createLessonLinks() {
        UAE.debug.info('links: ', this._linksMap);
        // extend in sub class. this._links
    }

    protected _genLinksForLesson(key: string, theme: number, lesson: number) {
        let prefix = 'k1_t' + theme + '_l' + lesson;
        this._linksMap.set(key, {
            lesson: this._getScript(prefix + '_s1_'),
            explore: this._getScript(prefix + '_e_'),
            practice: this._getScript(prefix + '_p_'),
            wrapup: this._getScript(prefix + '_w_')
        });     
    }

    protected _createButtons() {
        // extend this in sub, and call super._createButtons() at end
        UAE.debug.exposeGlobal(this._buttons, 'buttons');
        this._buttons.forEach((button) => {
            UAE.debug.info('btn name: ', button.name);
        })
    }

    protected _createButton(name: string, onDown: Function) {
        let button = this._goFactory.sprite(0, 0, name, 'up', this._playground, true);
        this._buttons.push(button);
        button.animations.importAnimations();
        button.input.enableInput();
        button.input.addInputListener('pointerdown', () => {
            this._buttons.forEach((button) => {
                button.animations.play('up');
            })
            button.animations.play('down');
            this._updateMenu(button.name);
            onDown();
        }, this);
    }
    //'k1_lesson_button', 'k1_practice_button' 'k1_explore_button k1_workout_button
    protected _createMenu() {
        let xPad = 10;
        let yOff = 35;
        let xOrigin = 0; 
        let yOrigin = 0;

        this._menu = this._goFactory.menu(UAE.game.width() / 2, UAE.game.height(), 'k1_submenu_nav', this._foreground);
        this._menu.setOrigin(0.5, 1);

        let lessonButton = this._goFactory.button(xPad + 125, yOff, 'k1_lesson_button', 'up', { up: 'up', down: 'selected', over: 'over', out: 'up' },
        ()=>{
            this._goto(this._currentLinks.lesson);
        }, this);
        lessonButton.setOrigin(xOrigin, yOrigin);
        this._menu.addButton(lessonButton);

        let exploreButton = this._goFactory.button(lessonButton.sprite.right + xPad, yOff, 'k1_explore_button', 'up', { up: 'up', down: 'selected', over: 'over', out: 'up' },
            () => {
               this._goto(this._currentLinks.explore);
            }, this);
        exploreButton.setOrigin(xOrigin, yOrigin);
        this._menu.addButton(exploreButton);

        let practiceButton = this._goFactory.button(exploreButton.sprite.right + xPad, yOff, 'k1_practice_button', 'up', { up: 'up', down: 'selected', over: 'over', out: 'up' },
            () => {
                this._goto(this._currentLinks.practice);
            }, this);
        practiceButton.setOrigin(xOrigin, yOrigin);
        this._menu.addButton(practiceButton);

        this._workoutButton = this._goFactory.button(practiceButton.sprite.right + xPad, yOff, 'k1_wrap_up_button', 'up', { up: 'up', down: 'selected', over: 'over', out: 'up' },
            () => {
                this._goto(this._currentLinks.wrapup);
            }, this);
        this._workoutButton.setOrigin(xOrigin, yOrigin);
        this._menu.addButton(this._workoutButton);

        this._menu.hide();
    }

    protected _updateMenu(name: any) {

        this._menu.show();
        let links = this._linksMap.get(name);
        UAE.debug.info('links: ', links);
        UAE.debug.info('linksMap: ', this._linksMap);
        if (links) { 
            this._currentLinks = links; 
            if(links.wrapup !== ''){
               this._workoutButton.show();
            }
            else {
                this._workoutButton.hide();
            }
        }
        else { UAE.debug.error('cannot assign links object that is undefined') }
    }

  /*   protected _updateLinks(name: string){
        let links = this._linksMap.get(name);
        if (links) { this._currentLinks = links; }
        else { UAE.debug.error('cannot assign links object that is undefined') }
    } */

    shutdown() {
      //  alert('shutown!');
        this._manager.globalEvents.emit('theme_menu_shutdown');
        this._manager.globalEvents.off('prev_tapped', this._onBackPressed, this);
        super.shutdown();
    }

}

export default AbstractThemeMenu;