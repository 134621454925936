import UAE from "UAE";
import AbstractThemeMenu from "./AbstractThemeMenu";

class ThemeMenu5 extends AbstractThemeMenu {
    constructor() {
        super(UAE.goFactory);
    }

    init() {
        super.init('k1_t5_menu', 'k1_t5_menu');
    }

    protected _createButtons() {
        this._createButton('t5_lesson_menu_l1', () => { });
        this._createButton('t5_lesson_menu_l2', () => { });
        this._createButton('t5_lesson_menu_l3', () => { });
        this._createButton('t5_lesson_menu_l4', () => { });
        this._createButton('t5_lesson_menu_l5', () => { });
        this._createButton('t5_lesson_menu_l6', () => { });
        this._createButton('t5_lesson_menu_l7', () => { });
        // this._createButton('t3_lesson_menu_l5', () => { UAE.debug.info('clicked button k3_theme_4_menu') }, () => { });
        super._createButtons();
    }


    protected _createLessonLinks(){
        this._genLinksForLesson('t5_lesson_menu_l1', 5, 1);
        this._genLinksForLesson('t5_lesson_menu_l2', 5, 2);
        this._genLinksForLesson('t5_lesson_menu_l3', 5, 3);
        this._genLinksForLesson('t5_lesson_menu_l4', 5, 4);
        this._genLinksForLesson('t5_lesson_menu_l5', 5, 5);
        this._genLinksForLesson('t5_lesson_menu_l6', 5, 6);
        this._genLinksForLesson('t5_lesson_menu_l7', 5, 7);
        super._createLessonLinks();
    }

    protected _onBackPressed() {
        this._goto('main_menu_2');
    }
}

export default ThemeMenu5;