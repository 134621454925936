import UAE from "UAE";
import AbstractThemeMenu from "./AbstractThemeMenu";

class ThemeMenu12 extends AbstractThemeMenu {
    constructor() {
        super(UAE.goFactory);
    }

    init() {
        super.init('k1_t12_menu', 'k1_t12_menu');
    }

    protected _createButtons() {
        this._createButton('t12_lesson_menu_1', () => { });
        this._createButton('t12_lesson_menu_2', () => { });
        this._createButton('t12_lesson_menu_3', () => { });
        this._createButton('t12_lesson_menu_4', () => { });
        this._createButton('t12_lesson_menu_5', () => { });
        this._createButton('t12_lesson_menu_6', () => { });
        // this._createButton('t3_lesson_menu_l5', () => { UAE.debug.info('clicked button k3_theme_4_menu') }, () => { });
        super._createButtons();
    }


    protected _createLessonLinks(){
        this._genLinksForLesson('t12_lesson_menu_1', 12, 1);
        this._genLinksForLesson('t12_lesson_menu_2', 12, 2);
        this._genLinksForLesson('t12_lesson_menu_3', 12, 3);
        this._genLinksForLesson('t12_lesson_menu_4', 12, 4);
        this._genLinksForLesson('t12_lesson_menu_5', 12, 5);
        this._genLinksForLesson('t12_lesson_menu_6', 12, 6);
        super._createLessonLinks();
    }

    protected _onBackPressed() {
        this._goto('main_menu_3');
    }
}

export default ThemeMenu12;