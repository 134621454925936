import UAE from 'UAE';
import MainMenu from '../Levels/MainMenu';
import TypeFactory from 'Game/GO/TypeFactory';
import ThemeMenu1 from 'Game/Levels/ThemeMenu1';
import ThemeMenu2 from 'Game/Levels/ThemeMenu2';
import ThemeMenu3 from 'Game/Levels/ThemeMenu3';
import ThemeMenu4 from 'Game/Levels/ThemeMenu4';
import MainMenu2 from 'Game/Levels/MainMenu2';
import MainMenu3 from 'Game/Levels/MainMenu3';
import ThemeMenu5 from 'Game/Levels/ThemeMenu5';
import ThemeMenu6 from 'Game/Levels/ThemeMenu6';
import ThemeMenu8 from 'Game/Levels/ThemeMenu8';
import ThemeMenu7 from 'Game/Levels/ThemeMenu7';
import ThemeMenu9 from 'Game/Levels/ThemeMenu9';
import ThemeMenu10 from 'Game/Levels/ThemeMenu10';
import ThemeMenu11 from 'Game/Levels/ThemeMenu11';
import ThemeMenu12 from 'Game/Levels/ThemeMenu12';

class Activity extends UAE.activities.BaseActivity {
  protected _typeFactory: TypeFactory;
  private _mainMenu: MainMenu;
  private _mainMenu2: MainMenu2;
  private _mainMenu3: MainMenu3;
  private _themeMenu1: ThemeMenu1;
  private _themeMenu2: ThemeMenu2;
  private _themeMenu3: ThemeMenu3;
  private _themeMenu4: ThemeMenu4;
  private _themeMenu5: ThemeMenu5;
  private _themeMenu6: ThemeMenu6;
  private _themeMenu7: ThemeMenu7;
  private _themeMenu8: ThemeMenu8;
  private _themeMenu9: ThemeMenu9;
  private _themeMenu10: ThemeMenu10;
  private _themeMenu11: ThemeMenu11;
  private _themeMenu12: ThemeMenu12;

  constructor(typeFactory: TypeFactory, mainMenu: MainMenu, mainMenu2: MainMenu2, mainMenu3: MainMenu3, themeMenu1: ThemeMenu1, themeMenu2: ThemeMenu2, themeMenu3: ThemeMenu3, themeMenu4: ThemeMenu4
    , themeMenu5: ThemeMenu5, themeMenu6: ThemeMenu6, themeMenu7: ThemeMenu7, themeMenu8: ThemeMenu8, themeMenu9: ThemeMenu9, themeMenu10: ThemeMenu10, themeMenu11: ThemeMenu11, 
    themeMenu12: ThemeMenu12) {
    super('k1', 'k1', UAE.game);
    this._typeFactory = typeFactory;
    this._mainMenu = mainMenu;
    this._mainMenu2 = mainMenu2;
    this._mainMenu3 = mainMenu3;
    this._themeMenu1 = themeMenu1;
    this._themeMenu2 = themeMenu2;
    this._themeMenu3 = themeMenu3;
    this._themeMenu4 = themeMenu4;
    this._themeMenu5 = themeMenu5;
    this._themeMenu6 = themeMenu6;
    this._themeMenu7 = themeMenu7;
    this._themeMenu8 = themeMenu8;
    this._themeMenu9 = themeMenu9;
    this._themeMenu10 = themeMenu10;
    this._themeMenu11 = themeMenu11;
    this._themeMenu12 = themeMenu12;
  }

  get typeFactory() {
    return this._typeFactory;
  }

  // POC - go to the menu speceified in the sceneName value
  startActivity(sceneName: string) {
    if (sceneName == 'main_menu') { super.startActivity(sceneName, this._mainMenu); }
    if (sceneName == 'main_menu_2') { super.startActivity(sceneName, this._mainMenu2); }
    if (sceneName == 'main_menu_3') { super.startActivity(sceneName, this._mainMenu3); }
    else if (sceneName == 'k1_t1_menu') { super.startActivity(sceneName, this._themeMenu1); }
    else if (sceneName == 'k1_t2_menu') { super.startActivity(sceneName, this._themeMenu2); }
    else if (sceneName == 'k1_t3_menu') { super.startActivity(sceneName, this._themeMenu3); }
    else if (sceneName == 'k1_t4_menu') { super.startActivity(sceneName, this._themeMenu4); }
    else if (sceneName == 'k1_t5_menu') { super.startActivity(sceneName, this._themeMenu5); }
    else if (sceneName == 'k1_t6_menu') { super.startActivity(sceneName, this._themeMenu6); }
    else if (sceneName == 'k1_t7_menu') { super.startActivity(sceneName, this._themeMenu7); }
    else if (sceneName == 'k1_t8_menu') { super.startActivity(sceneName, this._themeMenu8); }
    else if (sceneName == 'k1_t9_menu') { super.startActivity(sceneName, this._themeMenu9); }
    else if (sceneName == 'k1_t10_menu') { super.startActivity(sceneName, this._themeMenu10); }
    else if (sceneName == 'k1_t11_menu') { super.startActivity(sceneName, this._themeMenu11); }
    else if (sceneName == 'k1_t12_menu') { super.startActivity(sceneName, this._themeMenu12); }
    else {
      UAE.debug.error('k1 doesn\'t contain a menu called ', sceneName);
    }
  }
}

export default Activity;