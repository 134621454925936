import UAE from "UAE";
import AbstractThemeMenu from "./AbstractThemeMenu";

class ThemeMenu11 extends AbstractThemeMenu {
    constructor() {
        super(UAE.goFactory);
    }

    init() {
        super.init('k1_t11_menu', 'k1_t11_menu');
    }

    protected _createButtons() {
        this._createButton('t11_lesson_menu_1', () => { });
        this._createButton('t11_lesson_menu_2', () => { });
        this._createButton('t11_lesson_menu_3', () => { });
        this._createButton('t11_lesson_menu_4', () => { });
        this._createButton('t11_lesson_menu_5', () => { });
        this._createButton('t11_lesson_menu_6', () => { });
        // this._createButton('t3_lesson_menu_l5', () => { UAE.debug.info('clicked button k3_theme_4_menu') }, () => { });
        super._createButtons();
    }


    protected _createLessonLinks(){
        this._genLinksForLesson('t11_lesson_menu_1', 11, 1);
        this._genLinksForLesson('t11_lesson_menu_2', 11, 2);
        this._genLinksForLesson('t11_lesson_menu_3', 11, 3);
        this._genLinksForLesson('t11_lesson_menu_4', 11, 4);
        this._genLinksForLesson('t11_lesson_menu_5', 11, 5);
        this._genLinksForLesson('t11_lesson_menu_6', 11, 6);
        super._createLessonLinks();
    }

    protected _onBackPressed() {
        this._goto('main_menu_3');
    }
}

export default ThemeMenu11;