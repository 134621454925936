
import UAE from 'UAE';
import Button from 'UAE/Core/Engine/GameObjects/Button';
import MenuBar from 'UAE/Core/Engine/GameObjects/MenuBar';
import SpriteObject from 'UAE/Core/Engine/GameObjects/SpriteObject';
import ControlContainer from '../Dep/ControlContainer'
import TypeFactory from './GO/TypeFactory';
let allScripts = require('../../helpers/all_scripts.json'); // this gives us access to an array of all script names in the assets/scripts folder. Very handly
let control = new ControlContainer();
let mainActivity = control.getMain();

class ProductMain extends UAE.activities.AbstractAppMain {
    protected _factory: TypeFactory;
    protected _leftBar: MenuBar;
    protected _rightBar: MenuBar;
    protected _buttonYOffset: number;
    protected _showHideButton: SpriteObject;
    protected _lessonButtons: Button[];
    protected _paused: boolean;
    protected _curtain: SpriteObject;
    protected _wrapUpButton: Button;

    constructor() {
        super(UAE.game, mainActivity, UAE.goFactory, UAE.loader, UAE.levelManager);
        //  UAE.debug.info('constructed ProductMain');
        this._buttonYOffset = 10;
        this._lessonButtons = [];
        this._paused = false;
    };

    protected _init() {
        UAE.debug.info('called init in k1 main');
        super._init(allScripts);
    }

    protected _boot() {
        UAE.debug.info('called _boot in k1 main');
        // add assets that must be loaded FIRST, such as for a loader bar etc
        this._loader.addImages(['k1_loadingscreen'], 'jpg');
        this._loader.addImages(['k1_loadingbar', 'k1_loadingbartime'], 'png');
        super._boot();
    }

    protected _preload() {
        this.createLoadScreen('k1_loadingscreen', 'k1_loadingbar', 'k1_loadingbartime', 200, 22, -5);

        this._loader.addImages(['k1_lesson_panel_bar', 'k1_nav_bar_panel', 'k1_left_corner_ui',
            'k1_right_corner_ui'], 'png');
        this._loader.addAtlases(['back_button', 'forward_button', 'k1_explore_button',
            'k1_lesson_button', 'k1_practice_button',
            'k1_s_button', 'k1_wrap_up_button', 'nav_bar_up',
            'ok_button', 'pause_button', 'exit_button']);
        super._preload();
    }


    protected _start() {
        this._factory = mainActivity.typeFactory;
        super._start();
        this._buildLeftBar();
        this._buildRightBar();
        this._buildActivityBar();
        UAE.events.on('enter_transition', this._curtainIn, this);
        UAE.events.on('exit_transition', this._curtainOut, this);
        UAE.events.on('show_right', this._showRightBar, this);
        UAE.events.on('show_left', this._showLeftBar, this);
    }


    protected _showLeftBar() {
        this._leftBar.show();
    }

    protected _showRightBar() {
        this._rightBar.show();
    }

    protected _hideLeftBar() {
        this._leftBar.hide();
    }

    protected _hideRightBar() {
        this._rightBar.hide();
    }

    showActivityBar() {
        super.showActivityBar();
        this._updateLessonButtons(this._lessonButtonCodes());
        this._showRightBar();
        this._showLeftBar();
        //  this._showOK();
        this._showHideButton.visible = true;
        if(UAE.levelManager.script.rowByCellVals(['label'], ['wrap_tapped'])){
            this._wrapUpButton.show();
        }
        else {
            this._wrapUpButton.hide();
        }
    }

    hideActivityBar() {
        super.hideActivityBar();
        this._hideRightBar();
        this._hideLeftBar();
        this._hideOK();
        this._showHideButton.visible = false;
    }

    protected _curtainIn(data: {graphic: string}) {
        UAE.events.emit('resume');
        if (!this._curtain) {
            this._curtain = this._goFactory.sprite(0, 0, data.graphic, null, this._HUD);
            this._curtain.setOrigin(0, 1);
        }
        else {
            this._curtain.changeTexture(data.graphic);
        }
        this._curtain.y = UAE.game.height();
        this._curtain.tweens.once(UAE.tween.Easing.Sinusoidal.InOut, { y: UAE.game.height() + this._curtain.height }, 1000);
    }

    protected _curtainOut(data:{graphic: string}) {
        if (!this._curtain) {
            this._curtain = this._goFactory.sprite(0, 0, data.graphic, null, this._HUD);
            this._curtain.setOrigin(0, 1);
        }
        else {
            this._curtain.changeTexture(data.graphic);
        }
        this._curtain.y = UAE.game.height() + this._curtain.height
        this._curtain.tweens.once(UAE.tween.Easing.Sinusoidal.InOut, { y: UAE.game.height() }, 1000);
    }

    protected _buildLeftBar() {
        this._leftBar = UAE.goFactory.menu(0, UAE.game.height(), 'k1_left_corner_ui', this._HUD);
        this._leftBar.setOrigin(0, 1);
        let xOffset = 60;
        let backButton = UAE.goFactory.button(xOffset, this._leftBar.height / 2 + this._buttonYOffset, 'back_button', 'up',
            { over: 'over', out: 'up', up: 'up', down: 'up' }, () => { this._levelManager.globalEvents.emit('prev_tapped') }, this, undefined, undefined, this._HUD);
        backButton.setOrigin(0.5, 0.5);
        this._leftBar.addButton(backButton);
        this._levelManager.globalEvents.on('theme_menu_start', this._showLeftBar, this);
        this._levelManager.globalEvents.on('theme_menu_shutdown', this._hideLeftBar, this);
    }

    protected _buildRightBar() {
        this._rightBar = UAE.goFactory.menu(UAE.game.width(), UAE.game.height(), 'k1_right_corner_ui', this._HUD);
        this._rightBar.setOrigin(1, 1);
        // let xOffset = 20; 
        let pauseButton = UAE.goFactory.button(40, this._rightBar.height / 2 + this._buttonYOffset, 'pause_button', 'up',
            { over: 'over', out: 'up', up: 'up', down: 'up' }, () => { this._levelManager.globalEvents.emit('pause_tapped') }, this, undefined, undefined, this._HUD);
        pauseButton.setOrigin(0, 0.5);
        this._rightBar.addButton(pauseButton);

        let rightButton = UAE.goFactory.button(this._rightBar.width - 15, this._rightBar.height / 2 + this._buttonYOffset, 'forward_button', 'up',
            { over: 'over', out: 'up', up: 'up', down: 'up' }, () => { this._levelManager.globalEvents.emit('forward_tapped') }, this, undefined, undefined, this._HUD);
        rightButton.setOrigin(1, 0.5);
        this._rightBar.addButton(rightButton);
    }

    protected _addShowHideButton() {
        let x = this._leftBar.sprite.globalLeft + this._leftBar.sprite.width;
        // if (this._activityBar.sprite.parent) x += this._activityBar.sprite.parent.x;
        this._showHideButton = UAE.goFactory.sprite(x, UAE.game.height(), 'nav_bar_up', '', this._HUD);
        this._showHideButton.animations.importAnimations();
        this._showHideButton.setOrigin(1, 1);
        this._showHideButton.input.enableInput();

        this._showHideButton.input.addInputListener('pointerover', () => {
            // over
            if (this._activityBarIsDown) {
                this._showHideButton.animations.play('up-over');
            }
            else {
                this._showHideButton.animations.play('down-over');
            }
        }, this);

        this._showHideButton.input.addInputListener('pointerout', () => {
            // out
            if (this._activityBarIsDown) {

                this._showHideButton.animations.play('up-up');
            }
            else {
                this._showHideButton.animations.play('down-up');
            }
        }, this);

        this._showHideButton.input.addInputListener('pointerdown', () => {
            UAE.debug.info('y: ', this._activityBar.y);
            this._toggleActivityBar();
        }, this);
        // this._showHideButton.input.makePixelPerfect();
        UAE.debug.exposeGlobal(this._showHideButton, 'showHide');
    }

    protected _buildActivityBar() {
        super._buildActivityBar(this._leftBar.sprite.right + 100, UAE.game.height(), 'k1_nav_bar_panel'); // creates the barebones nav bar with background sprite. Must add buttons after.
        this._activityBar.setOrigin(0, 1);

        let xOffset = 60;
        let buttonStartOffset = 20;
        let yOffset = 5;
        let xPad = 20;
        let btnYOff = 10;

        let exitButton = UAE.goFactory.button(xOffset, this._activityBar.sprite.height / 2 + yOffset + btnYOff, 'exit_button', 'up',
            { up: 'up', down: 'selected', over: 'over', out: 'up' }, () => {
                UAE.events.emit('menu_tapped');
            }, this);

        exitButton.setOrigin(0, 0.5);
        this._activityBar.addButton(exitButton);

        let lessonBar = UAE.goFactory.sprite(exitButton.sprite.right + buttonStartOffset, this._activityBar.sprite.height / 2 + yOffset + btnYOff, 'k1_lesson_panel_bar',
            null, this._activityBar.group);
        lessonBar.setOrigin(0, 0.5);
        this._activityBar.addSubSprite(lessonBar);

        let exploreButton = UAE.goFactory.button(lessonBar.right + buttonStartOffset, this._activityBar.sprite.height / 2 + yOffset + btnYOff, 'k1_explore_button', 'up',
            { up: 'up', down: 'selected', over: 'over', out: 'up' }, () => {
                UAE.events.emit('expl_tapped');
            }, this);
        exploreButton.setOrigin(0, 0.5);
        this._activityBar.addButton(exploreButton);

        let practiceButton = UAE.goFactory.button(exploreButton.sprite.right + xPad, this._activityBar.sprite.height / 2 + yOffset + btnYOff, 'k1_practice_button', 'up',
            { up: 'up', down: 'selected', over: 'over', out: 'up' }, () => {
                UAE.events.emit('prac_tapped');
            }, this);
        practiceButton.setOrigin(0, 0.5);
        this._activityBar.addButton(practiceButton);

        let wrapupButton = UAE.goFactory.button(practiceButton.sprite.right + xPad, this._activityBar.sprite.height / 2 + yOffset + btnYOff, 'k1_wrap_up_button', 'up',
            { up: 'up', down: 'selected', over: 'over', out: 'up' }, () => {
                UAE.events.emit('wrap_tapped');
            }, this);
        wrapupButton.setOrigin(0, 0.5);
        this._activityBar.addButton(wrapupButton);
        this._wrapUpButton = wrapupButton;

        this._addLessonButtons({ startX: lessonBar.x, startY: lessonBar.top + lessonBar.height / 1.5, codes: ['dummmy'] })

        this._addShowHideButton();
        this._addOKButton();
        this._activityBar.states.createState('lesson');

        this.hideActivityBar();

        UAE.events.on('forward_tapped', this._onForwardTapped, this);
        UAE.events.on('expl_tapped', this._onExploreTapped, this);
        UAE.events.on('prac_tapped', this._onPracTapped, this);
        UAE.events.on('wrap_tapped', this._onWrapTapped, this);
        UAE.events.on('menu_tapped', this._onMenuTapped, this);
        UAE.events.on('prev_tapped', this._onPrevTapped, this);
        UAE.events.on('pause_tapped', this._onPauseTapped, this);
        UAE.events.on('hide_bar', this.hideActivityBar, this);
        // UAE.events.on('exit_tapped', this._onExitTapped, this);
    }


    protected _onPauseTapped() {
        if (this._paused) {
            UAE.events.emit('resume');
            this._paused = false;
        }
        else {
            UAE.events.emit('pause');
            this._paused = true;
        }
    }


    protected _addOKButton() {
        let xOffset = 40;
        let yOffset = 15;
        this._okButton = UAE.goFactory.button(this._rightBar.sprite.left + this._rightBar.sprite.width / 2 + xOffset, this._rightBar.sprite.top + yOffset, 'ok_button', 'up',
            //   this._okButton = UAE.goFactory.button(0, 0, 'ok_button', 'up', 
            { up: 'up', down: 'over', over: 'over', out: 'up' }, () => {
                UAE.events.emit('ok_pressed');
            }, this, undefined, undefined, this._HUD);
        this._okButton.setOrigin(0.5, 1);
        this._okButton.sprite.parent = this._rightBar.group;
        // this._okButton.sprite.parent = this._rightBar.sprite.parent;
        UAE.debug.exposeGlobal(this._okButton, 'ok');
        this._okButton.sprite.zIndex = -1;
        UAE.events.on('show_ok', this._showOK, this);
    }

    protected _toggleActivityBar() {
        if (this._activityBarIsDown) this._showHideButton.animations.play('down-over'); else this._showHideButton.animations.play('up-over');
        super._toggleActivityBar();
    }


    protected _addLessonButtons({ startX, startY, codes }: { startX: number, startY: number, codes: string[] }) {
        this._removeLessonButtons();
        this._createLessonButtons(startX, startY, codes);
        UAE.events.on('s_pressed', this._onSceneTapped, this);
    }

    protected _updateLessonButtons(codes: string[]) {
        let startX = this._lessonButtons[0].x;
        let startY = this._lessonButtons[0].y;
        this._createLessonButtons(startX, startY, codes);
    }

    protected _createLessonButtons(startX: number, startY: number, codes: string[]) {
        this._removeLessonButtons();
        let xOffset = 35;
        this._lessonButtons = [];
        for (let c = 1; c <= codes.length; c++) {
            let x = 0;
            if (c == 1) { x = startX; }
            else { x = this._lessonButtons[this._lessonButtons.length - 1].sprite.x + xOffset }
            let button = UAE.goFactory.button(x, startY, 'k1_s_button', 'up',
                { up: 'up', down: 'selected', over: 'over', out: 'up' }, () => {
                    UAE.events.emit('s_pressed', { sceneIndex: c });
                }, this);
            this._lessonButtons.push(button);
            button.setOrigin(0, 0.5);
            this._activityBar.addButton(button);
        }
    }

    protected _onSceneTapped(data: { sceneIndex: number }) {
        let label = 's' + data.sceneIndex + '_tapped';
        // Debug.info('label: ', label);
        UAE.game.startActivity(UAE.game._levelManager.script.rowByCellVals(['label'], [label]).config.go_to);
    }

    protected _onForwardTapped() {
        this.goToRow('forward_tapped');
    }

    protected _onExitTapped() {
        this.goToRow('exit_tapped');
    }

    protected _onPrevTapped() {
        let row = UAE.game._levelManager.script.rowByCellVals(['label'], ['prev_tapped']);
        // if(!row) row = UAE.game._levelManager.script.rowByCellVals(['label'], ['menu_tapped'])
        UAE.game.startActivity(row.config.go_to);
    }

    protected _onPracTapped() {
        UAE.game.startActivity(UAE.game._levelManager.script.rowByCellVals(['label'], ['prac_tapped']).config.go_to);
    }

    protected _onWrapTapped() {
        UAE.game.startActivity(UAE.game._levelManager.script.rowByCellVals(['label'], ['wrap_tapped']).config.go_to);
    }

    protected _onMenuTapped() {
        UAE.game.startActivity(UAE.game._levelManager.script.rowByCellVals(['label'], ['menu_tapped']).config.go_to);
    }

    protected _onExploreTapped() {
        UAE.game.startActivity(UAE.game._levelManager.script.rowByCellVals(['label'], ['expl_tapped']).config.go_to);
    }

    // get from current activity script
    protected _lessonButtonCodes(): string[] {
        let codes: string[] = [];
        for (let x = 1; x > 0; x++) {
            let row = UAE.levelManager.script.rowByCellVals(['label'], ['s' + x + '_tapped']);
            if (!row) {
                x = 0;
                return codes;
            }

            if (row.config && row.config.hasOwnProperty('go_to')) {
                codes.push(row.config.go_to);
            }
            else {
                UAE.debug.error('row: ', row.id, ' config cell has no go_to property -- ', row);
            }

        }
        return codes;
    }

    protected _removeLessonButtons() {
        this._lessonButtons.forEach((button) => {
            button.destroy();
        })

        this._lessonButtons = [];
    }

    goToRow(label: string) {
        let row = UAE.levelManager.script.rowByCellVals(['label'], [label]);
        if (row) UAE.levelManager.script.goTo(row);
        else UAE.debug.warn('row with label %s does not exist', label);
    }

}
UAE.debug.info('about to construct ProductMain in k1');
new ProductMain();
